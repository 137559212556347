import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  VStack,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Button,
  useBoolean,
  useToast,
  Text,
  Image,
  Center,
  FormErrorMessage,
} from "@chakra-ui/react"

import { useAuth } from '../../Contexts/auth';
import FakeLogo from '../../Assets/Images/fake-logo.png';

function Login() {
  const [loading, setLoading] = useBoolean();

  const toast = useToast();

  const { Authenticate } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmit = async (values: any) => {
    try {
      setLoading.on();
      await Authenticate(values.email, values.password);
    } catch (error: any) {
      let message = error.message;
      if (error.message.includes(401)) {
        message = 'Email e/ou senha incorretos'
      }

      toast({
        description: `${message}`,
        status: 'error',
        duration: 4000,
        position: 'top',
      })
    }
    finally {
      setLoading.off();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box textAlign="center" bgColor='blackAlpha.300' objectFit='cover'>
        <Grid minH="100vh" p={3}>
          <VStack spacing={12} justifyContent='center'>
            <Box w={[350, 350, 400]} bgColor='white' padding={5} borderRadius={2} shadow='lg'>
              {/* Aguardando logo */}
              {/* <Center>
                <Image src={FakeLogo} alt='Logo' height='120px' />
              </Center> */}
              <FormControl isInvalid={errors.email}>
                <FormLabel textColor='primary' htmlFor='email'>Email</FormLabel>
                <Input
                  id='email'
                  type='email'
                  focusBorderColor='primary'
                  bgColor='blackAlpha.300'
                  borderRadius={5}
                  {...register('email', {
                    required: 'Preencha o campo acima'
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password}>
                <FormLabel textColor='primary' marginTop={4} htmlFor='password'>Senha</FormLabel>
                <Input
                  id='password'
                  type='password'
                  focusBorderColor='primary'
                  bgColor='blackAlpha.300'
                  borderRadius={5}
                  {...register('password', {
                    required: 'Preencha o campo acima'
                  })}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                w='100%'
                padding={6}
                colorScheme='teal'
                marginTop={6}
                isLoading={loading}
                loadingText='Aguarde'
                type='submit'
              >
                <Text style={{ color: 'white !important' }}>Entrar</Text>
              </Button>
            </Box>
          </VStack>
        </Grid>
      </Box>
    </form>
  )
}

export default Login;