import * as React from 'react';
import { Box, HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBreakpointValue } from '@chakra-ui/react';
import { RiEditBoxFill, RiFileShield2Fill, RiFolderReceivedFill, RiLock2Fill } from 'react-icons/ri';

import ProfileEdit from './profile-edit';
import PasswordChange from './password-change';
import Credentials from './credentials';
import ImportData from './import-data';

function Profile() {
  const mainWidth = useBreakpointValue({
    base: '100%',
    sm: '100%',
    md: '100%',
    lg: '45%'
  });

  return (
    <Box height='100%' width={mainWidth} padding={{ sm: '200px', md: '0px', lg: '40px' }}>
      <Tabs orientation="horizontal" size='md' variant='enclosed'>
        <TabList
          overflowY="hidden"
          sx={{
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          paddingStart={['10px', '10px', '10px']}
          paddingEnd={['10px', '10px', '10px']}
          paddingTop={['10px', '10px', '10px']}
        >
          <Tab flexShrink={0}>
            <HStack alignItems='center' justifyContent='flex-start' width='100%'>
              <Icon as={RiEditBoxFill} />
              <Text>Editar perfil</Text>
            </HStack>
          </Tab>
          <Tab flexShrink={0}>
            <HStack alignItems='center' justifyContent='flex-start' width='100%'>
              <Icon as={RiLock2Fill} />
              <Text>Alterar senha</Text>
            </HStack>
          </Tab>
          <Tab flexShrink={0}>
            <HStack alignItems='center' justifyContent='flex-start' width='100%'>
              <Icon as={RiFileShield2Fill} />
              <Text>Chave de acesso</Text>
            </HStack>
          </Tab>
          <Tab flexShrink={0}>
            <HStack alignItems='center' justifyContent='flex-start' width='100%'>
              <Icon as={RiFolderReceivedFill} />
              <Text>Importar dados</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ProfileEdit />
          </TabPanel>
          <TabPanel>
            <PasswordChange />
          </TabPanel>
          <TabPanel>
            <Credentials />
          </TabPanel>
          <TabPanel>
            <ImportData />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Profile;