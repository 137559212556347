import * as React from 'react';
import { RiFolderReceivedFill, RiHome4Fill, RiLogoutBoxFill, RiSettings2Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Container,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBoolean,
  VStack
} from '@chakra-ui/react';

import { useAuth } from '../../Contexts/auth';
import Logo from '../../Assets/Images/fake-logo.png';
import BlankImage from '../../Assets/Images/blank_profile_image.png';

const SideMenu = () => {
  const { user, Logout } = useAuth();
  const [dialogState, setDialogState] = useBoolean();

  const userImage = user?.profileImageUrl || BlankImage;

  return (
    <Box width='fit-content' height='100vh' boxShadow='base'>
      <Container height='100%' paddingInlineStart='0px' paddingInlineEnd='0px'>
        <VStack height='100%' justifyContent='space-between' padding='0px 10px 0px 10px'>
          <div>
            {/* <Link to='/'>
              <img src={Logo} alt='Logo' />
            </Link> */}
            {/* aguardando logo */}
            <List marginTop={2}>
              <ListItem>
                <Link to='/'>
                  <HStack>
                    <ListIcon as={RiHome4Fill} color='primary' fontSize={20} marginInlineEnd='0px !important' />
                    <Text>Início</Text>
                  </HStack>
                </Link>
              </ListItem>
              <ListItem marginTop='20px'>
                <Link to='/email-receptor'>
                  <HStack>
                    <ListIcon as={RiFolderReceivedFill} color='primary' fontSize={20} marginInlineEnd='0px !important' />
                    <Text>Receptores</Text>
                  </HStack>
                </Link>
              </ListItem>
              <ListItem marginTop='20px'>
                <Link to='profile'>
                  <HStack>
                    <ListIcon as={RiSettings2Fill} color='primary' fontSize={20} marginInlineEnd='0px !important' />
                    <Text>Meu perfil</Text>
                  </HStack>
                </Link>
              </ListItem>
            </List>
          </div>
          <Menu closeOnSelect>
            <MenuButton paddingBottom={5}>
              <Avatar src={userImage} size='lg' />
            </MenuButton>
            <MenuList>
              <Link to="profile">
                <MenuItem icon={<Icon as={RiSettings2Fill} />}>
                  Meu perfil
                </MenuItem>
              </Link>
              <MenuItem onClick={() => { setDialogState.on() }} icon={<Icon as={RiLogoutBoxFill} />}>Sair</MenuItem>
            </MenuList>
          </Menu>
        </VStack>
      </Container>
      <AlertDialog
        isOpen={dialogState}
        onClose={() => { }}
        leastDestructiveRef={undefined}
        motionPreset='slideInBottom'
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Efetuando logout
            </AlertDialogHeader>
            <AlertDialogBody>
              Você deseja realmente sair?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => setDialogState.off()}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={Logout} ml={3}>
                Sair
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}

export default SideMenu;