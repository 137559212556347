import * as React from 'react';
import { Button, Text, useBoolean, useToast, VStack } from '@chakra-ui/react';

import { useAuth } from '../../Contexts/auth';
import api from '../../Services/api_service';
import { RiDownloadCloudFill } from 'react-icons/ri';

function Credentials() {
  const { Logout } = useAuth();
  const [loading, setLoading] = useBoolean();

  const toast = useToast();

  const downloadCredentialsFile = async () => {
    try {
      setLoading.on();
      const result = await api.post('subscription/credentials', null, true);
      exportToJson(result?.data || '')
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }

      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    } finally {
      setLoading.off();
    }
  }

  const downloadFile = (data: string, fileName: string, fileType: string) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToJson = (data: any) => {
    downloadFile(
      JSON.stringify(data),
      'keys.json',
      'text/json',
    )
  }

  return (
    <VStack height='100%' width='100%' alignItems='flex-start'>
      <Text>Clique no botão abaixo para realizar o download de sua <b>chave de acesso</b></Text>
      <Button
        marginTop={6}
        onClick={downloadCredentialsFile}
        isLoading={loading}
        loadingText='Baixando'
        leftIcon={<RiDownloadCloudFill />}
      >
        Baixar
      </Button>
    </VStack>
  )
}

export default Credentials;