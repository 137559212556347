import * as React from 'react';
import { Box, CircularProgress, VStack } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Box height='100%' width='100%'>
      <VStack height='100%' justifyContent='center'>
        <CircularProgress isIndeterminate color='primary' />
      </VStack>
    </Box >
  );
}

export default Loading;