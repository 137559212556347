import * as React from 'react';

import { useAuth } from '../Contexts/auth';
import Login from './login';
import PrivateRoutes from './private';

const Routes = () => {
  const { signed } = useAuth();
  return signed ? <PrivateRoutes /> : <Login />
}

export default Routes;