export enum WorkerStates {
  Idle = 0,
  ProcessingFiles = 1,
  SendingFiles = 2,
  InitialRequest = 3,
  DoneProcessingFiles = 4
}

export enum WorkerActions {
  ProcessFiles = 0,
  StopProcessing = 1,
  RequestAndSendFiles = 2,
}

export enum ConnectionWorkerStatus {
  Created = 1,
  Idle = 2,
  ProcessingFiles = 3,
  SendingFiles = 4,
  Failure = 5,
  Done = 6,
  Stopped = 7
}