import * as React from 'react';
import { RiEdit2Fill } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import Cropper from 'react-easy-crop';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Image,
  Text,
  useBoolean,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { useAuth } from '../../Contexts/auth';
import BlankProfileImage from '../../Assets/Images/blank_profile_image.png';
import api from '../../Services/api_service';
import { User } from '../../Types/user';
import getCroppedImg from '../../Helpers/crop-image';

function ProfileEdit() {
  const { user, Logout, UpdateUser } = useAuth();
  const image = user?.profileImageUrl || BlankProfileImage;
  const [submitting, setSubmitting] = useBoolean();
  const [imageSrc, setImageSrc] = React.useState('')
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
  const [croppedImage, setCroppedImage] = React.useState('')
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const hiddenFileInput = React.useRef<any>(null);

  const toast = useToast();

  const onSubmit = async (values: any) => {
    try {
      setSubmitting.on();
      user!.name = values.name;
      if (croppedImage !== '') {
        user!.profileImageUrl = croppedImage;
      }
      await api.put(`user/${user?.id}`, user, true);
      toast({
        description: 'Perfil atualizado com sucesso',
        status: 'info',
        position: 'top',
        duration: 4000,
      });
      UpdateUser(user as User);
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }

      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    } finally {
      setSubmitting.off();
    }
  }

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleClick = (event: any) => {
    hiddenFileInput!.current!.click();
  };

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl as string);
    }
  }

  const getCroppedImage = React.useCallback(async () => {
    try {
      const result = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setCroppedImage(result as string);
    } catch (error) {
      console.log(error);
    }
  }, [croppedAreaPixels, imageSrc]);

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack height='100%' width='100%' alignItems='flex-start'>
        <Text fontSize={20}>Foto</Text>
        <HStack>
          <Box height='200px' width='200px'>
            <Image src={croppedImage !== '' ? croppedImage : image} alt='Foto de perfil do usuário' borderRadius='50%' />
          </Box>
          <VStack height='100%' justifyContent='flex-end'>
            <input
              type={'file'}
              multiple={false}
              hidden
              accept='image/*'
              ref={hiddenFileInput}
              onChange={onFileChange}
            />
            <IconButton onClick={handleClick}
              icon={<RiEdit2Fill />}
              aria-label='Editar foto de perfil'
              variant='ghost'
              type='button'
            />
          </VStack>
        </HStack>
        {
          (imageSrc && croppedImage === '') &&
          <Box width={400} height={400} position='relative'>
            <VStack>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                  containerStyle: {
                    bottom: '50px'
                  }
                }}
                cropShape='round'
              />
              <HStack position='absolute' bottom={0}>
                <Button onClick={() => setImageSrc('')}>
                  Cancelar
                </Button>
                <Button colorScheme='teal' variant='solid' onClick={getCroppedImage}>
                  Recortar
                </Button>
              </HStack>
            </VStack>
          </Box>
        }
        <FormControl marginTop='15px !important'>
          <FormLabel htmlFor='user'>Email</FormLabel>
          <Input
            id='email'
            defaultValue={user?.email}
          />
        </FormControl>
        <FormControl isInvalid={errors.name}>
          <FormLabel htmlFor='user'>Nome</FormLabel>
          <Input
            id='name'
            type='text'
            defaultValue={user?.name}
            {...register('name', {
              required: 'Preencha o campo acima',
              minLength: { value: 3, message: 'Nome deve conter no minímo 3 caracteres' }
            })}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <Button marginTop='15px !important' bgColor='primary' color='whiteAlpha.900' _hover={{ color: 'black', bgColor: '#d9d9d9', transition: '0.2s linear' }} isLoading={submitting} type='submit'>
          Salvar
        </Button>
      </VStack>
    </form>
  )
}

export default ProfileEdit;