import * as React from 'react';
import { useTable } from "react-table";
import {
  chakra,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  HStack,
  Button,
  Icon,
  Text,
  Select,
} from "@chakra-ui/react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";

const CustomTable = (
  {
    columns,
    data,
    total,
    page,
    take,
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    takeOptions,
    setTakeOption
  }: {
    columns: any,
    data: any,
    total: number,
    page: number,
    take: number,
    nextPage: Function,
    previousPage: Function,
    firstPage?: Function
    lastPage?: Function,
    takeOptions: number[],
    setTakeOption: Function
  }
) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  const takeChange = (e: any) => {
    setTakeOption(Number.parseInt(e.target.value));
  }

  return (
    <Box>
      <Table {...getTableProps} overflow='auto'>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                  <chakra.span pl='4' />
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps({})} >
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Box marginTop='10px' paddingBottom='20px'>
        <HStack>
          {firstPage && <Button disabled={page === 1}>
            <Icon as={ArrowLeftIcon} />
          </Button>}
          <Button disabled={page === 1} onClick={() => previousPage()}>
            <Icon as={ChevronLeftIcon} />
          </Button>
          <Text>Página {page} de {Math.ceil(total / take)}</Text>
          <Button disabled={page === Math.ceil(total / take)} onClick={() => nextPage()}>
            <Icon as={ChevronRightIcon} />
          </Button>
          {lastPage && <Button disabled={page === Math.ceil(total / take)}>
            <Icon as={ArrowRightIcon} />
          </Button>}
          <Select width='5em' onChange={takeChange} id='select' value={take}>
            {takeOptions.map((takeOption, i) => (
              <option key={i} value={takeOption}>{takeOption}</option>
            ))}
          </Select>
          <Text>Linhas</Text>
        </HStack>
      </Box>
    </Box>
  );
}

export default CustomTable;