import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useBoolean,
  Button,
  FormErrorMessage
} from '@chakra-ui/react';

import { useAuth } from '../../Contexts/auth';
import api from '../../Services/api_service';

function PasswordChange() {
  const [submitting, setSubmitting] = useBoolean();
  const { user, Logout } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors }
  } = useForm();

  const toast = useToast();

  const onSubmit = async (values: any) => {
    try {
      setSubmitting.on();
      let altUser = { ...user };
      altUser = { ...altUser, ...values };
      await api.put(`user/${user?.id}`, altUser, true);
      toast({
        description: 'Senha alterada com sucesso',
        status: 'info',
        position: 'top',
        duration: 4000,
      });
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }

      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    } finally {
      setSubmitting.off();
    }
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack height='100%' width='100%' alignItems='flex-start'>
        <FormControl isInvalid={errors.password}>
          <FormLabel htmlFor='password'>Senha atual</FormLabel>
          <Input
            id='password'
            type='password'
            {...register('password', {
              required: 'Preencha o campo acima'
            })}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.newPassword}>
          <FormLabel htmlFor='newPassword'>Nova senha</FormLabel>
          <Input
            id='newPassword'
            type='password'
            {...register('newPassword', {
              required: 'Preencha o campo acima'
            })}
          />
          <FormErrorMessage>
            {errors.newPassword && errors.newPassword.message}
          </FormErrorMessage>
        </FormControl>
        <Button marginTop='15px !important' bgColor='primary' color='whiteAlpha.900' _hover={{ color: 'black', bgColor: '#d9d9d9', transition: '0.2s linear' }} isLoading={submitting} type='submit'>
          Salvar
        </Button>
      </VStack>
    </form>
  )
}

export default PasswordChange;