import * as React from 'react';
import { RiMailCheckFill, RiMailCloseFill, RiMailSendFill, RiPlayFill, RiSignalWifiErrorFill, RiStopFill } from 'react-icons/ri';
import Pusher, { Channel } from 'pusher-js';
import {
  Box,
  Center,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Progress,
  Skeleton,
  Text,
  useBoolean,
  useBreakpointValue,
  useToast,
  VStack
} from "@chakra-ui/react";

import api from '../../Services/api_service';
import { useAuth } from '../../Contexts/auth';
import { API_URL } from '../../Config/config';
import { ConnectionWorkerStatus, WorkerStates } from '../../Helpers/constants';

let controller: AbortController;
let signal: AbortSignal;

function Home() {
  const [loading, setLoading] = useBoolean(true);
  const [networkException, setNetworkException] = useBoolean();
  const [workerState, setWorkerState] = React.useState(WorkerStates.Idle);
  const [dashboardData, setDashboardData] = React.useState({ sended: 0, notSended: 0, failed: 0, connectionStatusId: 0 });
  const [pusherClient, setPusherClient] = React.useState({} as Pusher);
  const [pusherChannel, setPusherChannel] = React.useState({} as Channel);

  const toast = useToast();
  const { Logout, user, signed } = useAuth();

  React.useEffect(() => {
    if (!signed) {
      return;
    }
    let isMounted = true;
    if (loading) {
      fetchDashboardData().then((data) => {
        if (isMounted) {
          configuration();
          if (data) {
            setDashboardData({
              sended: data.sended,
              notSended: data.notSended,
              failed: data.failed,
              connectionStatusId: data.connectionStatusId
            });
            if (data.connectionStatusId !== 0) {
              const connectionStatusId = data.connectionStatusId;
              if (connectionStatusId === 3) {
                setWorkerState(WorkerStates.ProcessingFiles);
              } else {
                if (connectionStatusId === 4) {
                  setWorkerState(WorkerStates.SendingFiles);
                }
              }
            }
          }
          setLoading.off();
        }
      }).catch(() => {
        setLoading.off();
      })
    }

    return () => {
      isMounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    return () => {
      if (pusherClient.sessionID) {
        pusherClient.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchDashboardData = async () => {
    try {
      setNetworkException.off();
      const response = await api.get('dashboard', true);
      return response?.data;
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }

      if (error.message.includes('internet')) {
        setNetworkException.on();
      } else {
        setNetworkException.off();
      }

      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    }
  }

  const InfoCard = (props: any) => {
    return (
      <GridItem
        height='100%'
        width='100%'
        borderRadius='md'
        colSpan={props.colSpan}
        rowSpan={props.rowSpan}
        boxShadow='base'
        padding={[2, 5, 5, 5]}
      >
        <VStack height='100%' alignItems='center' justifyContent='space-between'>
          <Icon as={props.icon} height='4em' width='4em' />
          <Text fontSize={['sm', 'md', 'lg', 'xl']}>{props.text}</Text>
          <Skeleton isLoaded={!loading}>
            <Text paddingStart={5} paddingEnd={5} fontSize='2em'>{networkException ? <Icon as={RiSignalWifiErrorFill} /> : props.value}</Text>
          </Skeleton>
        </VStack>
      </GridItem>
    );
  }

  const DoWorkButton = () => {
    return (
      <Box height='100%' width='100%'>
        <Center height='100%'>
          <VStack>
            <IconButton
              bgColor='gray.200'
              borderRadius='100%'
              width='44'
              height='44'
              icon={<DoWorkButtonIcon />}
              aria-label='Começar trabalho'
              onClick={workerState ? cancelWork : dispatch}
              disabled={loading}
            />
            <Feedback />
          </VStack>
        </Center>
      </Box>
    );
  }

  const DoWorkButtonIcon = () => {
    if (workerState.valueOf() !== WorkerStates.Idle.valueOf()) {
      return <RiStopFill color='primary' fontSize={60} />
    } else {
      return <RiPlayFill color='primary' fontSize={60} />
    }
  }

  const Feedback = () => {
    switch (workerState) {
      case WorkerStates.InitialRequest:
        return (
          <VStack>
            <Progress size='xs' colorScheme='blackAlpha' isIndeterminate width='100%' />
            <Text>Estabelecendo conexão com cliente</Text>
          </VStack>
        );
      case WorkerStates.ProcessingFiles:
        return (
          <VStack>
            <Progress size='xs' colorScheme='blackAlpha' isIndeterminate width='100%' />
            <Text>Processando arquivos</Text>
          </VStack>
        );
      case WorkerStates.SendingFiles:
        return (
          <VStack>
            <Progress size='xs' colorScheme='blackAlpha' isIndeterminate width='100%' />
            <Text>Enviando arquivos</Text>
          </VStack>
        );
      case WorkerStates.DoneProcessingFiles:
        return (
          <VStack>
            <Progress size='xs' colorScheme='blackAlpha' isIndeterminate width='100%' />
            <Text>Requisitando dados do servidor</Text>
          </VStack>
        );
      default:
        return (<Box height='44px'><Text color='white'>Lorem Ipsum</Text></Box>)
    }
  }

  const cancelWork = async () => {
    if (!controller) {
      controller = new AbortController();
      controller.abort();
    }
    if (pusherChannel) {
      const triggerData = {
        companyId: user?.companyId,
        accessKey: user?.accessKey,
        connectionStatus: `${ConnectionWorkerStatus.Stopped.valueOf()}`,
        actionToFire: '1'
      };
      pusherChannel.trigger(`client-update-connection`, triggerData);
      setWorkerState(WorkerStates.Idle);
      toast({
        description: 'Operação cancelada pelo usuário',
        status: 'info',
        duration: 4000,
        position: 'top'
      });
    } else {
      // canal nao encontrado
      toast({
        description: 'Não foi possível cancelar a operação. Recarregue a página e tente novamente',
        status: 'error',
        duration: 4000,
        position: 'top'
      });
    }
  }

  const dispatch = async () => {
    try {
      controller = new AbortController();
      signal = controller.signal;
      setWorkerState(WorkerStates.ProcessingFiles);
      const result = await api.post('Work/Dispatch', null, true, signal);
      if (result === undefined) {
        if (signal.aborted) {
          return;
        }
      }
      setTimeout(() => {
        const triggerData = {
          companyId: user?.companyId,
          accessKey: user?.accessKey,
        };
        pusherChannel.trigger('client-check-connection', triggerData);
      }, 6000);
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }
      setWorkerState(WorkerStates.Idle);
    }
  }

  const configuration = async () => {
    try {
      if (pusherClient.sessionID === undefined) {
        const pusher = new Pusher('e01ddb20898fba87f58d', {
          cluster: 'sa1',
          authEndpoint: `${API_URL}/Auth/ValidateAccessKey`,
          enabledTransports: ["ws"],
        });
        setPusherClient(pusher);
        const channel = pusher.subscribe(`private-${user?.accessKey}`)
        setPusherChannel(channel);
        channel.unbind_global();
        channel.unbind_all();
        channel.bind('action', actionListener);
        channel.bind('connection-status-result', checkConnectionStatus);
        channel.bind('sending-files-result', isSendingFiles);
        channel.bind('done-result', isDone);
        channel.bind('error-with-message-prop', errorWithMessageProp);
        channel.bind('info-with-message-prop', infoWithMessageProp);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const actionListener = (data: any) => {
    switch (data.Action) {
      case 1:
        setWorkerState(WorkerStates.Idle);
        break;

      default:
        break
    }
  }

  const checkConnectionStatus = (data: any) => {
    if (!data.Ack) {
      setWorkerState(WorkerStates.Idle);
      toast({
        description: 'Conexão não foi confirmada pelo cliente desktop. Verifique se o cliente está aberto ou se a chave'
          + ' de acesso está no caminho C:\\',
        status: 'error',
        position: 'top',
        duration: 30000,
        isClosable: true
      });
    }
  }

  const isSendingFiles = (data: any) => {
    if (data.SendingFiles) {
      setWorkerState(WorkerStates.DoneProcessingFiles);
      setTimeout(() => {
        setWorkerState(WorkerStates.SendingFiles);
      }, 3000);
    }
  }

  const isDone = (data: any) => {
    if (data.Done) {
      setWorkerState(WorkerStates.Idle);
      toast({
        description: 'Operação concluída com êxito',
        status: 'success',
        position: 'top',
        duration: 4000,
      });
      setLoading.on();
    }
  }

  const errorWithMessageProp = (data: any) => {
    setWorkerState(WorkerStates.Idle);
    toast({
      description: `${data.Message}`,
      status: 'warning',
      position: 'top',
      duration: 7000,
      isClosable: true
    });
  }

  const infoWithMessageProp = (data: any) => {
    toast({
      description: `${data.Message}`,
      status: 'info',
      position: 'top',
      duration: 7000,
      isClosable: true
    });
  }

  const templateColumns = useBreakpointValue({
    base: 12,
    sm: 12,
    md: 14,
    lg: 10
  });

  const gridTemplateColumns = useBreakpointValue({
    base: `repeat(${templateColumns}, 1fr)`,
    sm: `repeat(${templateColumns}, 1fr)`,
    md: `repeat(${templateColumns}, 1fr)`,
    lg: `repeat(${templateColumns}, 1fr)`
  });

  const cardColSpan = useBreakpointValue({
    base: 5,
    sm: 5,
    md: 6,
    lg: 2,
  });

  const gridTemplateRows = useBreakpointValue({
    base: 'repeat(10, 1fr)'
  });

  const hideOnMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  return (
    <Box height='100%'>
      <Grid height='100%' gridTemplateColumns={gridTemplateColumns} gridTemplateRows={gridTemplateRows}>
        <GridItem height='100%' width='100%' colSpan={templateColumns} rowSpan={1}></GridItem>

        <GridItem height='100%' width='100%' colSpan={1} rowSpan={3}></GridItem>

        <InfoCard colSpan={cardColSpan} icon={RiMailCheckFill} text='Enviados' value={dashboardData.sended} />

        <GridItem hidden={hideOnMobile} height='100%' width='100%' colSpan={1} rowSpan={3}></GridItem>

        <InfoCard colSpan={cardColSpan} icon={RiMailCloseFill} text='Falha no envio' value={dashboardData.failed} />

        <GridItem height='100%' width='100%' colSpan={1} rowSpan={3}></GridItem>

        <GridItem colSpan={cardColSpan} rowSpan={1}>
          <InfoCard colSpan={cardColSpan} rowSpan={1} icon={RiMailSendFill} text='Aguardando envio' value={dashboardData.notSended} />
        </GridItem>

        <GridItem hidden={hideOnMobile} height='100%' width='100%' colSpan={templateColumns} rowSpan={1}></GridItem>

        <GridItem height='100%' width='100%' colSpan={templateColumns} rowSpan={1}>
          <DoWorkButton />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default Home;