import axios from 'axios';

import { User } from '../Types/user';
import { API_URL } from '../Config/config';

const http = axios.create({
  baseURL: API_URL
});

http.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    originalRequest._retry = true;
    const expiratedUser = JSON.parse(localStorage.getItem("@App:user") as string);
    const response = await post('auth/refreshtoken', expiratedUser);
    if (response?.status === 200) {
      localStorage.setItem("@App:user", JSON.stringify(response.data));
      originalRequest.headers.Authorization = `Bearer ${response?.data.token}`;
      return http(originalRequest);
    }
  }
})

const post = async (url: string, data: any, requiresAuth = false, signal?: AbortSignal) => {
  try {
    if (requiresAuth) {
      if (localStorage.getItem('@App:user') === null) {
        return;
      }

      const user: User = JSON.parse(localStorage.getItem('@App:user') ?? '');
      http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }

    http.defaults.headers.common['content-type'] = 'application/json';
    return await http.post(url, data !== null ? data : undefined, { signal });
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      return Promise.reject({ message: error?.message });
    }

    if (error?.message?.includes('500')) {
      return Promise.reject({ message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
    }

    if (error?.message?.includes('Network Error')) {
      return Promise.reject({ message: 'Verifique sua conexão com a internet e tente novamente' });
    }

    return Promise.reject(error?.response?.data || { message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
  }
}

const get = async (url: string, requiresAuth = false) => {
  try {
    if (requiresAuth) {
      if (localStorage.getItem('@App:user') === null) {
        return;
      }

      const user: User = JSON.parse(localStorage.getItem('@App:user') ?? '');
      http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }

    http.defaults.headers.common['content-type'] = 'application/json';
    return await http.get(url);
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      return Promise.reject({ message: error?.message });
    }

    if (error?.message?.includes('500')) {
      return Promise.reject({ message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
    }

    if (error?.message?.includes('Network Error')) {
      return Promise.reject({ message: 'Verifique sua conexão com a internet e tente novamente' });
    }

    return Promise.reject(error?.response?.data || { message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
  }
}

const put = async (url: string, data: any, requiresAuth = false) => {
  try {
    if (requiresAuth) {
      if (localStorage.getItem('@App:user') === null) {
        return;
      }

      const user: User = JSON.parse(localStorage.getItem('@App:user') ?? '');
      http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }

    http.defaults.headers.common['content-type'] = 'application/json';
    return await http.put(url, data !== null ? data : undefined);
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      return Promise.reject({ message: error?.message });
    }

    if (error?.message?.includes('500')) {
      return Promise.reject({ message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
    }

    if (error?.message?.includes('Network Error')) {
      return Promise.reject({ message: 'Verifique sua conexão com a internet e tente novamente' });
    }

    return Promise.reject(error?.response?.data || { message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
  }
}

const _delete = async (url: string) => {
  if (localStorage.getItem('@App:user') === null) {
    return;
  }

  const user: User = JSON.parse(localStorage.getItem('@App:user') ?? '');
  http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

  http.defaults.headers.common['content-type'] = 'application/json';
  return await http.delete(url);
}

const postFormData = async (url: string, data: FormData) => {
  try {
    if (localStorage.getItem('@App:user') === null) {
      return;
    }

    const user: User = JSON.parse(localStorage.getItem('@App:user') ?? '');
    http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    return await http.post(url, data);
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      return Promise.reject({ message: error?.message });
    }

    if (error?.message?.includes('500')) {
      return Promise.reject({ message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
    }

    if (error?.message?.includes('Network Error')) {
      return Promise.reject({ message: 'Verifique sua conexão com a internet e tente novamente' });
    }

    return Promise.reject(error?.response?.data || { message: 'Ocorreu um erro. Aguarde alguns instantes e tente novamente' });
  }
}

const ApiService = {
  post,
  get,
  put,
  _delete,
  postFormData,
}

export default ApiService;