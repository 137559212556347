import * as React from 'react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import Login from '../Pages/Login/login';

export default function OtherRoutes() {
  return (
    <Router>
      <Switch>
        <Route path="*" element={<Login />} />
        <Route path="/" element={<Login />} />
      </Switch>
    </Router>
  );
}