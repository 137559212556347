import * as React from 'react';
import { Box, Button, HStack, useBoolean, useToast } from '@chakra-ui/react';
import { RiDownloadCloudFill, RiUploadCloudFill } from 'react-icons/ri';
import FileSaver from 'file-saver';

import api from '../../Services/api_service';
import { useAuth } from '../../Contexts/auth';

const ImportData = () => {
  const [loading, setLoading] = useBoolean();
  const [submitting, setSubmitting] = useBoolean();

  const { Logout } = useAuth();

  const toast = useToast();

  const hiddenFileInput = React.useRef<any>(null);

  const requestFile = async () => {
    try {
      setLoading.on();
      const result = await api.get('import', true);
      console.log(result);
      handleResponse(result?.data);
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }

      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    } finally {
      setLoading.off();
    }
  }

  const handleResponse = (data: string) => {
    const bin = atob(data);
    const ab = s2ab(bin);
    const blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    FileSaver.saveAs(blob, 'modelo_de_importacao.xlsx');
  }

  const s2ab = (s: string) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF
    };
    return buf;
  }

  const getBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const onFileChange = async (e: any) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        setSubmitting.on();
        const base64 = await getBase64(e.target.files[0]);
        const result = await api.post('import', { base64: base64 }, true);
        if (result?.status === 200) {
          toast({
            description: 'Importação efetuada com sucesso',
            status: 'success',
            position: 'top',
            duration: 4000,
          });
        }
        setSubmitting.off();
      }
    } catch (error: any) {
      toast({
        description: `${error?.message}`,
        status: 'error',
        position: 'top',
        duration: 30000,
        isClosable: true
      })
      setSubmitting.off();
    }

    e.target.files = undefined
  }

  const handleClick = (event: any) => {
    hiddenFileInput!.current!.click();
  };

  return (
    <Box width='100%'>
      <HStack justifyContent='flex-start' alignItems='center'>
        <Button
          onClick={requestFile}
          isLoading={loading}
          loadingText='Baixando'
          leftIcon={<RiDownloadCloudFill />}
        >
          Baixar planilha de importação
        </Button>
        <Button
          onClick={handleClick}
          isLoading={submitting}
          loadingText='Importando'
          leftIcon={<RiUploadCloudFill />}
        >
          Importar planilha
        </Button>
        <input
          type={'file'}
          multiple={false}
          hidden
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ref={hiddenFileInput}
          onChange={onFileChange}
        />
      </HStack>
    </Box>
  );
}

export default ImportData;