import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Box, Grid, GridItem, HStack, useBreakpointValue, useMediaQuery, VStack, } from '@chakra-ui/react';
import {
  Routes as Switch,
  Route,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";

import Content from '../Layout/Content/content';
import Home from '../Pages/Home/home';
import SideMenu from '../Layout/SideMenu/side-menu';
import Profile from '../Pages/Profile/profile';
import TopMenu from '../Layout/TopMenu/top-menu';
import EmailReceptorsList from '../Pages/EmailReceptors/email-receptors-list';

const history = createBrowserHistory();

function PrivateRoutes() {
  const [isMobile] = useMediaQuery('(max-width: 480px)')

  const Layout = ({ children }: { children: any }) => {
    return isMobile ?
      (
        <VStack height='100vh' minHeight='100vh'>
          <TopMenu />
          <Content>
            {children}
          </Content>
        </VStack>
      )
      :
      (
        <Box>
          <HStack alignItems='flex-start'>
            <Box flex={1}>
              <SideMenu />
            </Box>
            <Box flex={9} overflow='auto'>
              <Content>
                {children}
              </Content>
            </Box>
          </HStack>
        </Box>
      );
  }

  return (
    <React.Fragment>
      <HistoryRouter history={history}>
        <Layout>
          <Switch>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/email-receptor" element={<EmailReceptorsList />} />
          </Switch>
        </Layout>
      </HistoryRouter>
    </React.Fragment>
  );
}

export default PrivateRoutes;