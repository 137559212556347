import * as React from 'react';
import { Box, Button, HStack, Icon, Input } from '@chakra-ui/react';
import { RiEraserFill, RiSearch2Fill } from 'react-icons/ri';

const Search = ({ updateState, searchFunc, eraseSearch, showEraseButton }: { updateState: Function, searchFunc: Function, eraseSearch: Function, showEraseButton: boolean }) => {
  return (
    <HStack>
      <Box width='70%'>
        <Input placeholder='Buscar' onChange={(e: any) => updateState(e)} />
      </Box>
      <Button onClick={() => searchFunc()}>
        <Icon as={RiSearch2Fill} />
      </Button>
      {showEraseButton && <Button leftIcon={<RiEraserFill />} onClick={(e: any) => eraseSearch()}>
        Limpar
      </Button>}
    </HStack >
  );
}

export default Search;