import * as React from 'react';
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, HStack, IconButton, Image, Input, Spacer, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import {
  Box,
  useBoolean,
  useToast,
} from "@chakra-ui/react";

import { EmailReceptor } from '../../Types/email-receptor';
import { useAuth } from '../../Contexts/auth';
import api from '../../Services/api_service';
import CustomTable from '../../Components/table';
import Search from '../../Components/search';
import { RiDeleteBin7Fill, RiEditFill } from 'react-icons/ri';
import DeletePopover from '../../Components/delete-popover';
import EditPopover from '../../Components/edit-popover';
import CreatePopover from '../../Components/create-popover';
import { useForm } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import Loading from '../../Components/loading';
import EmptyState from '../../Assets/Images/searching.png';

const EmailReceptorsList = () => {
  const [loading, setLoading] = useBoolean(true);
  const [networkException, setNetworkException] = useBoolean();
  const [emailReceptors, setEmailReceptors] = React.useState([] as EmailReceptor[]);
  const [totalReceptors, setTotalReceptors] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [take, setTake] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [updateComponent, setUpdateComponent] = useBoolean();

  const mainWidth = useBreakpointValue({
    base: '100%',
    sm: '100%',
    md: '100%',
    lg: '45%'
  });

  const { Logout, signed } = useAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: "CNPJ",
        accessor: 'cnpj'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: () => null,
        id: 'edit',
        Cell: ({ row }: { row: any }) => (
          <HStack>
            <EditPopover form={<Form id={row.original.id} email={row.original.email} cnpj={row.original.cnpj} action={updateEmailReceptor} />}>
              <IconButton icon={<RiEditFill />} aria-label='edit' />
            </EditPopover>
            <DeletePopover id={row.original.id} action={deleteEmailReceptor}>
              <IconButton colorScheme='red' icon={<RiDeleteBin7Fill />} aria-label='edit' />
            </DeletePopover>
          </HStack>
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []
  );

  const takeOptions = React.useMemo(
    () => [10, 20, 50], []
  );

  const toast = useToast();

  React.useEffect(() => {
    if (!signed) {
      return;
    }

    setLoading.on();
    fetchEmailReceptors().then((data) => {
      if (data) {
        setEmailReceptors(data.receptors);
        setTotalReceptors(data.total);
      }
      setLoading.off();
    }).catch(() => { setLoading.off() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take, page, updateComponent]);

  const nextPage = async () => {
    setLoading.on();
    let actualPage = page;
    actualPage += 1;
    setPage(actualPage);
  }

  const previousPage = async () => {
    setLoading.on();
    let actualPage = page;
    actualPage -= 1;
    setPage(actualPage);
  }

  const setTakeOption = async (value: number) => {
    if (value !== take) {
      setLoading.on();
      const actualTake = value;
      setTake(actualTake);
      setPage(1);
    }
  }

  const fetchEmailReceptors = async () => {
    try {
      setNetworkException.off();
      const skip = (page - 1) * take;
      const response = await api.get(`receptor?take=${take}&skip=${skip}&search=${search}`, true);
      return response?.data;
    } catch (error: any) {
      if (error?.message?.includes('401')) {
        Logout();
        return;
      }

      if (error?.message?.includes('internet')) {
        setNetworkException.on();
      } else {
        setNetworkException.off();
      }

      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    }
  }

  const deleteEmailReceptor = async (id: number) => {
    try {
      const result = await api._delete(`receptor/${id}`);
      if (result?.status === 200) {
        toast({
          description: 'Removido com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000,
        });

        setLoading.on();
        setUpdateComponent.toggle();
      }
    } catch (error: any) {
      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    }
  }

  const updateEmailReceptor = async (id: number, data: any) => {
    try {
      const result = await api.put(`receptor/${id}`, data, true);
      if (result?.status === 200) {
        toast({
          description: 'Atualizado com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000,
        });
        setLoading.on();
        setUpdateComponent.toggle();
      }
    } catch (error: any) {
      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    }
  }

  const createEmailReceptor = async (data: any) => {
    try {
      const result = await api.post('receptor', data, true);
      if (result?.status === 200) {
        toast({
          description: 'Atualizado com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000,
        });
        setLoading.on();
        setUpdateComponent.toggle();
      }
    } catch (error: any) {
      toast({
        description: `${error.message}`,
        status: 'error',
        position: 'top',
        duration: 4000,
      });
    }
  }

  const searchFieldChange = (e: any) => {
    setSearch(e.target.value);
  }

  const searchFunc = () => {
    setUpdateComponent.toggle();
  }

  const eraseSearch = () => {
    setSearch('');
    setUpdateComponent.toggle();
  }

  return (
    <Box width={mainWidth} height='100%' padding={{ sm: '200px', md: '0px', lg: '40px' }}>
      <React.Fragment>
        <HStack paddingTop='10px' justifyContent='space-between' alignItems='center'>
          <Search
            updateState={searchFieldChange}
            searchFunc={searchFunc}
            eraseSearch={eraseSearch}
            showEraseButton={search.length > 0}
          />
          <CreatePopover form={<Form cnpj='' email='' id={-1} action={createEmailReceptor} />}>
            <Button leftIcon={<AddIcon />}>
              Novo
            </Button>
          </CreatePopover>
        </HStack>
        {loading && <Loading />}
        {(!loading && totalReceptors > 0) &&
          (
            <CustomTable
              columns={columns}
              data={emailReceptors}
              total={totalReceptors}
              page={page}
              take={take}
              nextPage={nextPage}
              previousPage={previousPage}
              takeOptions={takeOptions}
              setTakeOption={setTakeOption}
            />
          )}
      </React.Fragment>
      {(!loading && totalReceptors === 0) &&
        (
          <VStack>
            <Image src={EmptyState} width='70%' />
            <Text>Nenhum receptor foi encontrado</Text>
          </VStack>
        )
      }
    </Box>
  )
}


const Form = ({ id, cnpj, email, action }: { id: number, cnpj: string, email: string, action: Function }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values: any) => {
    if (id !== -1) {
      await action(id, values);
    }
    else {
      await action(values);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack height='100%' width='100%' alignItems='flex-start'>
        <FormControl isInvalid={errors.cnpj}>
          <FormLabel htmlFor='user'>CNPJ</FormLabel>
          <Input
            id='cnpj'
            type='text'
            defaultValue={cnpj}
            maxLength={18}
            {...register('cnpj', {
              required: 'Preencha o campo acima',
            })}
          />
          <FormErrorMessage>
            {errors.cnpj && errors.cnpj.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email}>
          <FormLabel htmlFor='user'>Email</FormLabel>
          <Input
            id='email'
            type='email'
            defaultValue={email}
            {...register('email', {
              required: 'Preencha o campo acima',
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Insira um email válido',
              },
            })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <Button
          marginTop='15px !important'
          bgColor='primary'
          color='whiteAlpha.900'
          _hover={{ color: 'black', bgColor: '#d9d9d9', transition: '0.2s linear' }}
          type='submit'
          loadingText='Salvando'
        >
          Salvar
        </Button>
      </VStack>
    </form>
  )
}

export default EmailReceptorsList;