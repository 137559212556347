import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react';

const DeletePopover = ({ children, id, action }: { children: any, id: number, action: Function }) => {

  return (
    <Popover>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Confirmação</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Você tem certeza que deseja remover este item?
        </PopoverBody>
        <PopoverFooter d='flex' justifyContent='flex-end'>
          <ButtonGroup size='sm'>
            <Button variant='outline'>Não</Button>
            <Button colorScheme='red' onClick={() => action(id)}>Sim</Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export default DeletePopover;