import * as React from "react"
import {
  ChakraProvider,
  extendTheme
} from "@chakra-ui/react"

import Routes from "./Routes/routes";
import { AuthProvider } from "./Contexts/auth";

const theme = extendTheme({
  colors: {
    primary: '#1A2238',
    secondary: '#9DAAF2',
    background: '#FF6A3D',
    accent: '#F4DB7D'
  },
})

export const App = () => {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Routes />
      </ChakraProvider>
    </AuthProvider>
  );
}
