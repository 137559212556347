import * as React from 'react';
import { Link } from 'react-router-dom';
import { RiSettings2Fill, RiLogoutBoxFill, RiFolderReceivedFill } from 'react-icons/ri';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBoolean
} from '@chakra-ui/react';

import { useAuth } from '../../Contexts/auth';
import BlankImage from '../../Assets/Images/blank_profile_image.png';
import Logo from '../../Assets/Images/fake-logo.png';

const TopMenu = () => {

  const { user, Logout } = useAuth();
  const [dialogState, setDialogState] = useBoolean();

  const userImage = user?.profileImageUrl || BlankImage;

  return (
    <Box height='10vh' width='100%'>
      <Container height='100%' width='100%' maxWidth='100% important'>
        <HStack justifyContent='space-between' alignItems='center' height='100%'>
          <Box height='100%'>
            {/* <Link to='/'>
              <Image src={Logo} height='100%' />
            </Link> */}
            {/* Aguardando logo */}
          </Box>
          <Menu closeOnSelect>
            <MenuButton>
              <Avatar src={userImage} size='lg' />
            </MenuButton>
            <MenuList>
              <Link to="email-receptor">
                <MenuItem icon={<Icon as={RiFolderReceivedFill} />}>
                  Receptores
                </MenuItem>
              </Link>
              <Link to="profile">
                <MenuItem icon={<Icon as={RiSettings2Fill} />}>
                  Meu perfil
                </MenuItem>
              </Link>
              <MenuItem onClick={() => { setDialogState.on() }} icon={<Icon as={RiLogoutBoxFill} />}>Sair</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Container>
      <AlertDialog
        isOpen={dialogState}
        onClose={() => { }}
        leastDestructiveRef={undefined}
        motionPreset='slideInBottom'
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Efetuando logout
            </AlertDialogHeader>
            <AlertDialogBody>
              Você deseja realmente sair?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => setDialogState.off()}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={Logout} ml={3}>
                Sair
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default TopMenu;