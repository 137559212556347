import * as React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';

const Content = ({ children }: { children: any }) => {
  const height = useBreakpointValue({ base: '89vh', sm: '89vh', md: '89vh', lg: '100vh' });
  const width = useBreakpointValue({ base: '100%', sm: '70%', md: '70%', lg: '100%' })
  return (
    <Box height={height} width={width}>
      <>{children}</>
    </Box>
  );
}

export default Content;