import * as React from 'react';
import { createStandaloneToast, useBoolean } from '@chakra-ui/react';

import { AuthContextData } from '../Types/auth-context-data';
import api from '../Services/api_service';
import { User } from '../Types/user';

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = React.useState<User | null>(null);
  const [userUpdated, setUserUpdated] = useBoolean();

  const toast = createStandaloneToast();

  React.useEffect(() => {
    const storageUser = localStorage.getItem("@App:user");
    if (storageUser) {
      setUser(JSON.parse(storageUser));
    }
  }, []);

  React.useEffect(() => {
    const storageUser = localStorage.getItem("@App:user");
    setUser(JSON.parse(storageUser!));
  }, [userUpdated]);

  const Authenticate = async (email: string, password: string) => {
    try {
      const response = await api.post('auth', { email, password });
      localStorage.setItem("@App:user", JSON.stringify(response!.data));
      setUser(response!.data);
    } catch (e) {
      throw e;
    }
  }

  const Logout = (data?: any) => {
    setUser(null);
    localStorage.clear();

    if (data === undefined) {
      toast({
        description: 'Sua sessão expirou',
        status: 'warning',
        duration: 3000,
        position: 'top'
      });
    }

    window.history.pushState("", "", "/");
  }

  const UpdateUser = React.useCallback((data: User) => {
    localStorage.setItem("@App:user", JSON.stringify(data));
    setUser(data);
    setUserUpdated.toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, Authenticate, Logout, UpdateUser }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  return context;
}

export default AuthContext;